import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Footer from "src/elements/Footer";
import Header from "src/elements/Header";
import DownloadAppButton from "src/elements/DownloadAppButton";
import { Container, Buttons } from "src/theme/layout";
import { colors } from "src/theme/variables";
import { Typography, Button } from "src/theme/elements";
import { mediaMd, mediaSm } from "src/theme/utils";
import { PageReset } from "../components/helpers";

const StyledContent = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 250px;
  padding-bottom: 250px;
  text-align: center;

  ${mediaMd`
    padding-top: 150px;
    padding-bottom: 150px;
  `}

  ${mediaSm`
    padding-top: 70px;
    padding-bottom: 70px;
  `}
`;

const StyledDescription = styled(Typography).attrs({
  settings: {
    tag: "p",
  },
})`
  margin-top: 8px;
  margin-bottom: 40px;
  color: ${colors.darkGray};
`;

const SignUpSuccessPage = () => (
  <Layout header={<Header variant="dark" />} footer={<Footer />}>
    <PageReset />

    <StyledContent>
      <Typography settings={{ tag: "h4", dark: true }}>Great 👍. Your account has been created</Typography>
      <StyledDescription>
        You can use the web version or download our app for MacOS to log in AppSpector
      </StyledDescription>
      <Buttons>
        <Button to="https://app.appspector.com/login" variant="bordered">
          Use web version
        </Button>
        <DownloadAppButton />
      </Buttons>
    </StyledContent>
  </Layout>
);

export default SignUpSuccessPage;
